@import '~antd/lib/style/themes/default';

// Borders
@border-radius-xlarge: 32px;
@border-radius-large: 24px;
@border-radius-medium: 16px;
@border-radius-small: 8px;
@border-radius-xsmall: 4px;
@border-radius-xxsmall: 0px;
@border-radius-base: @border-radius-small;
@border-color-base: #dbdbdb;

// Colors
@pink-color: #b80b7b;
@dark-color: #0a1250;
@violet-color: #530099;
@purple-color: #753fa7;
@primary-color: @violet-color;
@link-color: @violet-color;
@info-color: @blue-6;
@table-row-hover-bg: #f0f5ff;
@primary-light-color: #B37FEB;

// Layout
@layout-body-background: #fafafa;
@layout-header-background: @white;
@layout-sider-background-light: @dark-color;
@layout-trigger-background-light: @dark-color;

// Content
@content-border: 1px solid @border-color-base;
@content-border-radius: @border-radius-medium;
@content-background-color: @white;

// Misc
@btn-border-radius-base: @border-radius-small;
@card-radius: @border-radius-medium;
@card-background: @content-background-color;
@progress-default-color: @primary-color;

@table-border-radius-base: @border-radius-small;
@toolbar-background: lighten(@divider-color, 50%);
@tooltip-border-radius: @border-radius-xxsmall;
@control-border-radius: @border-radius-small;
@tag-border-radius: @border-radius-small;

@tree-node-selected-bg: #e9e0f1;

@modal-mask-bg: fade(@dark-color, 50%);
